<template>
    <div class="col-12 text-center">
        <div class="maximum-width">
            <template v-if="notEnoughData || jetonDepositError">
                <!--Error-->
                <error></error>
                <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
                <p v-else-if="jetonDepositError" class="alert alert-danger mt-5 text-center">{{jetonDepositError}} {{$t('request-unsuccessful')}}</p>
            </template>
            <div v-else-if="showLoader">
                <div class="text-center mt-5">
                    <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
                </div>
            </div>
            <div v-else-if="(limitReached && maxDeposit > 0)">
                <form @submit.prevent="submit" novalidate>
                    <limits-amount v-on:playerAmount="playerAmount" :amount="amount" :playerCurrencySymbol="playerCurrencySymbol" :limitReached="limitReached" :minDeposit="minDeposit" :maxDeposit="maxDeposit"></limits-amount>
                    <button type="button" @click="submit()" class="btn btn-success col-12 mt-3" :class="{'disabled':submitStatus === 'PENDING'}">
                        <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                        <div v-else class="d-flex align-items-center">
                            <strong>{{$t('loading')}}...</strong>
                            <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    </button>
                </form>
            </div>
            <div v-if="limitReached && maxDeposit <= 0">
                <p class="alert alert-danger mt-3 text-center" v-if="limitReached">{{limitReached}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {getProviderUrl} from "../../../services/backend.service";

    export default {
        name: 'JetonDeposit',
        data() {
            return {
                notEnoughData: null,
                jetonDepositError: null,
                showLoader:true,
                paymentOption: null,
                language: null,
                customerId: null,
                ipAddress: null,
                playerCurrency: null,
                playerCurrencySymbol: null,
                brandUrl: null,
                amount: null,
                minDeposit: 5,
                maxDeposit: 100,
                limitReached: null,
                submitStatus: null,
                showBankSection: false,

            }
        },
        components: {
            Error: () => import('../../others/Error'),
            LimitsAmount: () => import('../../others/LimitsAmount'),
        },
        mounted() {
            this.paymentOption = this.$router.history.current.query.paymentOption;
            this.language = this.$router.history.current.query.language;
            this.customerId = this.$router.history.current.query.customerId;
            this.ipAddress = this.$router.history.current.query.ipAddress;
            this.playerCurrency = this.$router.history.current.query.playerCurrency;
            this.playerCurrencySymbol = this.$router.history.current.query.playerCurrencySymbol;
            this.amount = this.$router.history.current.query.amount;
            this.brandUrl = this.$router.history.current.query.brandUrl;
            this.accountInvalid = this.$router.history.current.query.errorCode;

            if(!this.ipAddress || !this.playerCurrency || !this.amount || !this.playerCurrencySymbol || !this.customerId ){
                this.notEnoughData = true;
            } else {
                this.jetonDeposit();
            }
        },
        methods: {
            jetonDeposit() {
                getProviderUrl({
                    currency: this.playerCurrency,
                    amount: this.amount,
                    processorName: "JETON",
                    processorType: "EWALLET",
                    walletType : "DEFAULT",
                    customerId: this.customerId,
                    ipAddress: this.ipAddress,
                    language: this.language,
                    merchantRef : this.merchantRef,
                    brandUrl: this.brandUrl
                }).then(res => {
                    this.showLoader = false;
                    this.submitStatus = null;
                    if(res.data && res.data.success && res.data.data && res.data.data.redirectUrl){
                        window.location = res.data.data.redirectUrl;
                    } else if(res.data && res.data.success && res.data.data && res.data.data.minLimitAmount !== null && res.data.data.maxLimitAmount !== null && res.data.data.errorCode && res.data.data.errorCode !== 'account-invalid') {
                        this.minDeposit = Math.floor(res.data.data.minLimitAmount/100);
                        this.maxDeposit = Math.floor(res.data.data.maxLimitAmount/100);
                        this.amount = Math.floor(res.data.data.maxLimitAmount/100);
                        this.limitReached = this.$t(res.data.data.errorCode);
                    } else {
                        this.jetonDepositError = this.$t('error-2');
                    }
                }).catch( e => {
                    this.showLoader = false;
                    this.jetonDepositError = this.$t('error-2');
                    console.log(`%c Error in JetonDeposit.vue - method: jetonDeposit - ${e}`, 'color:red');
                });
            },
            submit() {
               if(this.limitReached && this.minDeposit && this.maxDeposit && this.amount){
                    this.submitStatus = 'PENDING';
                    this.jetonDeposit();
                }
            },
            playerAmount(value){
                this.amount = value;
            }
        }
    }
</script>
